const perDayPrice = 0.1;

export default class PriceUtils {
  static convert(strPrice: string, dayOfYear: number): string {
    if (strPrice.startsWith("D:")) {
      const multiplier = parseFloat(strPrice.slice(2));
      return `$${(perDayPrice * dayOfYear * multiplier).toFixed(2)}`;
    } else {
      return strPrice;
    }
  }
}
