import React from "react";
import { makeStyles } from "@mui/styles";
import { NavLink } from "react-router-dom";
import CustomAppBar from "./components/CustomAppBar";

const useStyles = makeStyles(() => ({
  stepper: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
    width: "100%",
    height: "50px",
    zIndex: "100",
    display: "flex",
    flexDirection: "row",
  },
  spacer: {
    height: "100%",
    flexBasis: 0,
    flexGrow: 1,
  },
  overlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0px",
    left: "0px",
  },
  slideBoxContainer: {
    position: "relative",
    top: "50%",
    left: "50%",
  },
  slideBox: {
    position: "relative",
    backgroundColor: "rgba(255, 255, 255, 0.5)"
  },
  slideTitle: {
    width: "100%",
    textAlign: "center",
    font: "25px Montserrat",
    color: "#0F0F0F",
    fontWeight: "300 !important",
    paddingTop: "20px",
  },
  slideDescription: {
    width: "100%",
    textAlign: "center",
    font: "14px Montserrat",
    color: "#2F2E2E",
    fontWeight: "300 !important",
    paddingTop: "10px",
  },
  slideAction: {
    width: "100%",
    textAlign: "center",
    paddingTop: "40px",
  },
  slideActionLink: {
    textDecoration: "none !important",
    font: "14px Montserrat",
    color: "#2F2E2E",
    fontWeight: "400 !important",
  },
}));

type HomeControllerProps = {
  width: number;
  height: number;
};

function HomeController({
  width,
  height,
}: HomeControllerProps) {
  const classes = useStyles();

  React.useEffect(() => {
    document.title = "amazing-shapes.com";
  }, []);

  return (
    <div>
      <CustomAppBar section="home" />
      <div style={{ position: "relative" }}>
        <img
          src="/images/homescreen.png"
          style={{
            objectFit: "cover",
            width: width,
            height: height,
          }}
        />
        <div className={classes.overlay}>
          <div className={classes.slideBoxContainer}>
            <div
              className={classes.slideBox}
              style={{ top: "200px", left: "-120px", width: "340px", height: "170px" }}
            >
              <div className={classes.slideTitle}>Amazing Shapes</div>
              <div className={classes.slideDescription}>Are you ready to be amazed with beautiful shapes?</div>
              <div className={classes.slideAction}>
                <NavLink className={classes.slideActionLink} to="/all-products">
                  SHOP
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeController;
