import React from "react";
import { makeStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeController from "./HomeController";
import AllProductsController from "./AllProductsController";
import TwoDShapesController from "./TwoDShapesController";
import BestSellersController from "./BestSellersController";
import SaleController from "./SaleController";
import ProductController from "./ProductController";

const useStyles = makeStyles(() => ({
  root: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "fixed",
    display: "flex",
    flexDirection: "column",
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#1876D2",
    },
  },
});

export default function AppRouter() {
  const classes = useStyles();
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });
  const rootRef = React.useRef(null);

  React.useEffect(() => {
    const handleResize = () => {
      if (rootRef.current) {
        setDimensions({
          width: (rootRef.current as any).clientWidth,
          height: (rootRef.current as any).clientHeight,
        });
      }
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  });

  React.useEffect(() => {
    if (rootRef.current) {
      setDimensions({
        width: (rootRef.current as any).clientWidth,
        height: (rootRef.current as any).clientHeight,
      });
    }
  }, [rootRef]);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root} ref={rootRef}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={<HomeController width={dimensions.width} height={dimensions.height} />}
            />
            <Route
              path="/all-products"
              element={<AllProductsController />}
            />
            <Route
              path="/2d-shapes"
              element={<TwoDShapesController />}
            />
            <Route
              path="/best-sellers"
              element={<BestSellersController />}
            />
            <Route
              path="/sale"
              element={<SaleController />
              }
            />
            <Route
              path="/products/:section/:productId"
              element={<ProductController />}
            />
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}
