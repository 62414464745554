import React from "react";
import { Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles(() => ({
  container: {
    position: "absolute",
    top: "20px",
    width: "100%",
    height: "60px",
    zIndex: "100",
    display: "flex",
    flexDirection: "row",
  },
  spacer: {
    height: "100%",
    flexBasis: 0,
    flexGrow: 1,
  },
  toolbar: {
    boxShadow: "none !important",
    height: "60px",
    lineHeight: "60px",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    padding: "0px !important",
    font: "16px Montserrat",
    cursor: "pointer",
  },
  toolbrLeftItem: {
    width: "110px",
    backgroundColor: "rgba(255, 255, 255, .5)",
    color: "#919090 !important",
    textDecoration: "none !important",
    border: "1px solid #919090",
    borderTopLeftRadius: "30px",
    borderBottomLeftRadius: "30px",
    "&:hover": {
      backgroundColor: "rgba(185, 185, 185, 1) !important",
      color: "#FFFFFF",
    },
  },
  toolbarMiddleItem: {
    width: "140px",
    backgroundColor: "rgba(255, 255, 255, .5)",
    color: "#919090 !important",
    textDecoration: "none !important",
    borderTop: "1px solid #919090",
    borderBottom: "1px solid #919090",
    borderRight: "1px solid #919090",
    "&:hover": {
      backgroundColor: "rgba(185, 185, 185, 1) !important",
      color: "#FFFFFF",
    },
  },
  toolbarRightItem: {
    width: "110px",
    backgroundColor: "rgba(255, 255, 255, .5)",
    color: "#919090 !important",
    textDecoration: "none !important",
    borderTop: "1px solid #919090",
    borderBottom: "1px solid #919090",
    borderRight: "1px solid #919090",
    borderTopRightRadius: "30px",
    borderBottomRightRadius: "30px",
    "&:hover": {
      backgroundColor: "rgba(185, 185, 185, 1) !important",
      color: "#FFFFFF",
    },
  },
}));

type CustomAppBarProps = {
  section: string;
};

function CustomAppBar({
  section,
}: CustomAppBarProps) {
  const classes = useStyles();

  const activeStyle = {
    backgroundColor: "rgba(185, 185, 185, 0.5)",
    color: "#FFFFFF",
  };

  return (
    <div className={classes.container}>
      <div className={classes.spacer} />
      <Toolbar variant="dense" className={classes.toolbar}>
        <NavLink
          className={classes.toolbrLeftItem}
          to="/"
          style={section === 'home' ? activeStyle : undefined}
        >
          Home
        </NavLink>
        <NavLink
          className={classes.toolbarMiddleItem}
          to="/all-products"
          style={section === 'all-products' ? activeStyle : undefined}
        >
          All Products
        </NavLink>
        <NavLink
          className={classes.toolbarMiddleItem}
          to="/2d-shapes"
          style={section === '2d-shapes' ? activeStyle : undefined}
        >
          2D Shapes
        </NavLink>
        <NavLink
          className={classes.toolbarMiddleItem}
          to="/best-sellers"
          style={section === 'best-sellers' ? activeStyle : undefined}
        >
          Best Sellers
        </NavLink>
        <NavLink
          className={classes.toolbarRightItem}
          to="/sale"
          style={section === 'sale' ? activeStyle : undefined}
        >
          Sale
        </NavLink>
      </Toolbar>
      <div className={classes.spacer} />
    </div>
  );
}

export default CustomAppBar;
