import React from "react";
import CustomAppBar from "./components/CustomAppBar";
import ProductList from "./components/ProductList";

export default function BestSellersController() {
  React.useEffect(() => {
    document.title = "Best Sellers | amazing-shapes.com";
  }, []);

  return (
    <div>
      <CustomAppBar section="best-sellers" />
      <ProductList section="best-sellers" />
    </div>
  );
}
