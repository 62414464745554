import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useParams } from 'react-router-dom';
import CustomAppBar from "./components/CustomAppBar";
import Shapes from "./data/shapes.json";
import { Shape, DisplayOption } from "./Types";
import DayOfTheYearUtil from "./utils/DayOfTheYearUtil";
import PriceUtils from "./utils/PriceUtils";

const useStyles = makeStyles(() => ({
  container: {
    position: "absolute",
    top: "100px",
    left: "20px",
    right: "20px",
    bottom: "20px",
    display: "flex",
    flexDirection: "row",
  },
  imagePane: {
    width: "50%",
    height: "100%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "row",
    paddingRight: "10px",
  },
  productDetailsPane: {
    width: "50%",
    height: "100%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "10px",
  },
  thumbnails: {
    width: "100px",
    minWidth: "100px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  thumbnail: {
    width: "80px",
    minWidth: "80px",
    height: "100px",
    minHeight: "100px",
    objectFit: "cover",
    border: "1px solid #C4C4C4",
    cursor: "pointer",
  },
  imageContainer: {
    flexGrow: 1,
    border: "1px solid #C4C4C4",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  titlePriceVisitContainer: {
    width: "100%",
    height: "50px",
    display: "flex",
    flexDirection: "row",
  },
  titleContainer: {
    flexBasis: "100px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflow: "hidden",
  },
  title: {
    width: "100%",
    height: "25px",
    color: "#575757",
    font: "20px Helvetica, Arial, sans-serif",
    overflow: "hidden",
  },
  visitButtonContainer: {
    width: "150px",
    minWidth: "150px",
    height: "100%",
    marginTop: "20px",
  },
  visitButton: {
    width: "100%",
  },
  optionLabel: {
    width: "100%",
    height: "25px",
    color: "#848484",
    font: "16px Helvetica, Arial, sans-serif",
    fontWeight: 700,
    marginTop: 20,
  },
  optionContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  option: {
    cursor: "pointer",
    padding: "5px 10px 5px 10px",
    margin: "5px 8px 5px 0px",
    color: "#575757",
    border: "1px solid #C4C4C4",
    borderRadius: "4px",
    font: "14px Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 500,
  },
  description: {
    width: "100%",
    font: "14px Helvetica, Arial, sans-serif",
    color: "#575757",
    padding: "20px 10px 0px 0px",
  },
}));

function ProductController() {
  const classes = useStyles();
  const params = useParams();
  const [shape, setShape] = React.useState<Shape | undefined>(undefined);
  const [dayOfTheYear, setDayOfTheYear] = React.useState<number>(0);
  const [selectedImageIndex, setSelectedImageIndex] = React.useState<number>(0);
  const [addToCartDialogOpen, setAddToCartDialogOpen] = React.useState(false);
  const [addToCartDialogShown, setAddToCartDialogShown] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      const currentDayOfYear = await DayOfTheYearUtil.get();
      const currentShape = Shapes.find((item) => item.id === params.productId);
      setDayOfTheYear(currentDayOfYear);
      setShape(currentShape);
      document.title = `${currentShape?.title} | amazing-shapes.com`;
    })();
  }, []);

  let priceElement: any = null;
  let finalPrice: number = 0;
  if (addToCartDialogShown) {
    priceElement = (<div>$0.00</div>);
    finalPrice = 0;
  }
  else if (shape && shape.price) {
    if (shape.salePrice) {
      const finalPriceStr = PriceUtils.convert(shape.salePrice, dayOfTheYear);
      priceElement = (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ textDecoration: "line-through" }}>{PriceUtils.convert(shape.price, dayOfTheYear)}</div>
          <div style={{ paddingLeft: 5, color: "#E60023" }}>{finalPriceStr}</div>
        </div>
      );
      finalPrice = parseFloat(finalPriceStr.slice(1));
    } else {
      const finalPriceStr = PriceUtils.convert(shape.price, dayOfTheYear);
      priceElement = (<div>{finalPriceStr}</div>);
      finalPrice = parseFloat(finalPriceStr.slice(1));
    }
  }

  return (
    <div>
      <CustomAppBar section={params.section || ""} />
      {shape ? (
        <div className={classes.container}>
          <div className={classes.imagePane}>
            <div className={classes.thumbnails}>
              {shape.images.map((image, index) => {
                return (
                  <img
                    key={`thumbnail_${index}`}
                    className={classes.thumbnail}
                    style={{
                      borderColor: index === selectedImageIndex ? "#666666" : "#C4C4C4",
                      marginBottom: index === shape.images.length - 1 ? 0 : 10,
                    }}
                    src={image}
                    onClick={() => setSelectedImageIndex(index)}
                  />
                );
              })}
            </div>
            <div className={classes.imageContainer}>
              {selectedImageIndex !== -1 ? (
                <img className={classes.image} src={shape.images[selectedImageIndex]} />
              ) : null}
            </div>
          </div>
          <div className={classes.productDetailsPane}>
            <div className={classes.titlePriceVisitContainer}>
              <div className={classes.titleContainer}>
                <div className={classes.title}>{shape.title}</div>
                <div className={classes.title}>{priceElement}</div>
              </div>
            </div>
            {shape.options.map((option: DisplayOption) => {
              return (
                <div key={option.name}>
                  <div className={classes.optionLabel}>{option.name.toUpperCase()}</div>
                  <div className={classes.optionContainer}>
                    {option.values.map((displayOptionValue: string) => (
                      <div
                        key={displayOptionValue}
                        className={classes.option}
                        style={{ backgroundColor: "#FFFFFF" }}
                        onClick={() => {}}
                      >
                        {displayOptionValue.toUpperCase()}
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
            <div
              className={classes.description}
              dangerouslySetInnerHTML={{ __html: shape.description }}
            />
            <div className={classes.visitButtonContainer}>
              <Button
                className={classes.visitButton}
                color="primary"
                variant="contained"
                disableElevation
                disabled={addToCartDialogShown}
                onClick={() => {
                  setAddToCartDialogOpen(true)
                }}
              >
                ADD TO CART
              </Button>
            </div>
          </div>
        </div>
      ) : null}
      <Dialog open={addToCartDialogOpen} onClose={() => setAddToCartDialogOpen(false)}>
        <DialogContent>
          <DialogContentText>Just kidding! Amazing shapes are absolutely free.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAddToCartDialogShown(true);
              setAddToCartDialogOpen(false);
            }}
            color="primary"
            variant="contained"
            disableElevation
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>      
    </div>
  );
}

export default ProductController;
