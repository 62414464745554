import React from "react";
import CustomAppBar from "./components/CustomAppBar";
import ProductList from "./components/ProductList";

export default function AllProductsController() {
  React.useEffect(() => {
    document.title = "All Products | amazing-shapes.com";
  }, []);

  return (
    <div>
      <CustomAppBar section="all-products" />
      <ProductList section="all-products" />
    </div>
  );
}
