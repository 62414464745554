import React from "react";
import CustomAppBar from "./components/CustomAppBar";
import ProductList from "./components/ProductList";

export default function SaleController() {
  React.useEffect(() => {
    document.title = "Sale | amazing-shapes.com";
  }, []);

  return (
    <div>
      <CustomAppBar section="sale" />
      <ProductList section="sale" />
    </div>
  );
}
