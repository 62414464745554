import React from "react";
import { makeStyles } from "@mui/styles";
import { NavLink } from "react-router-dom";
import Shapes from "../data/shapes.json";
import { Shape } from "../Types";
import DayOfTheYearUtil from "../utils/DayOfTheYearUtil";
import PriceUtils from "../utils/PriceUtils";

const useStyles = makeStyles(() => ({
  container: {
    position: "absolute",
    top: "100px",
    left: "10px",
    right: "10px",
    bottom: "0px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  product: {
    width: "212px",
    height: "322px",
    border: "1px solid #999999",
    margin: 5,
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    textDecoration: "none !important",
  },
  image: {
    width: "210px",
    height: "280px",
    objectFit: "cover",
    borderBottom: "1px solid #999999",
  },
  title: {
    width: "100%",
    height: "20px",
    lineHeight: "20px",
    padding: "0 5px",
    font: "14px Helvetica, Arial, sans-serif",
    color: "#333333",
    overflow: "hidden",
  },
}));

type ProductListProps = {
  section: string;
};

function ProductList({
  section,
}: ProductListProps) {
  const classes = useStyles();
  const [dayOfTheYear, setDayOfTheYear] = React.useState<number>(0);
  const [shapes, setShapes] = React.useState<Shape[]>([]);

  React.useEffect(() => {
    (async () => {
      const currentDayOfYear = await DayOfTheYearUtil.get();
      const currentShapes = Shapes.filter((item) => item.category.includes(section));
      setDayOfTheYear(currentDayOfYear);
      setShapes(currentShapes);
    })();
  }, [section]);

  return (
    <div className={classes.container}>
      {shapes.map((shape) => {
        let priceElement: any = null;
        if (shape && shape.price) {
          if (shape.salePrice) {
            priceElement = (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ textDecoration: "line-through" }}>{PriceUtils.convert(shape.price, dayOfTheYear)}</div>
                <div style={{ paddingLeft: 5, color: "#E60023" }}>{PriceUtils.convert(shape.salePrice, dayOfTheYear)}</div>
              </div>
            );
          } else {
            priceElement = (<div>{PriceUtils.convert(shape.price, dayOfTheYear)}</div>);
          }
        }
        return (
          <NavLink
            key={shape.id}
            className={classes.product}
            to={`/products/${section}/${shape.id}`}
          >
            <img
              className={classes.image}
              src={shape.images[0]}
              loading="lazy"
            />
            <div className={classes.title}>{shape.title}</div>
            <div className={classes.title}>{priceElement}</div>
          </NavLink>
        );
      })}
    </div>
  );
}

export default ProductList;
