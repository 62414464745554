import axios from "axios";

const oneDay = 1000 * 60 * 60 * 24;

export default class DayOfTheYearUtil {
  static async get() {
    const response = await axios.get(
      `/empty.json`,
      {
        timeout: 60 * 1000,
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    const strDate = response.headers.date;
    const now = new Date(strDate);
    const start = new Date(now.getFullYear(), 0, 0);
    const diff = now.getTime() - start.getTime();
    const dayOfTheYear = Math.ceil(diff / oneDay); 

    return dayOfTheYear;
  }
}
