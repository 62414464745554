import React from "react";
import CustomAppBar from "./components/CustomAppBar";
import ProductList from "./components/ProductList";

export default function TwoDShapesController() {
  React.useEffect(() => {
    document.title = "2D Shapes | amazing-shapes.com";
  }, []);

  return (
    <div>
      <CustomAppBar section="2d-shapes" />
      <ProductList section="2d-shapes" />
    </div>
  );
}
